/* eslint-disable */

export const validate = (...rules) => rules.reduce((acc, cur) => {
  const res = acc;

  let error = null;
  try {
    cur();
  } catch (e) {
    error = [e.field, e.message];
  }

  return {
    result: res.result && !error,
    errors: [...res.errors, ...(error ? [error] : [])],
  };
}, { result: true, errors: [] });

// RegExp
const emailRegExp = new RegExp(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/);
const phoneRegExp = new RegExp(/^((8|\+7)[\- ]?)?(\(?\d{3,4}\)?[\- ]?)?[\d\- ]{5,10}$/);

// Validate rules
export const fieldRequiredValidator = (model, field, message = '') => () => {
  if (!model[field]) {
    throw { field, message };
  }
};

export const emailValidator = (model, field, message = '') => () => {
  if (!model[field].match(emailRegExp)) {
    throw { field, message };
  }
};

export const phoneValidator = (model, field, message = '') => () => {
  if (!model[field].match(phoneRegExp)) {
    throw { field, message };
  }
};

export const lengthValidator = (model, field, length, message = '') => () => {
  if (model[field].length !== length) {
    throw { field, message };
  }
};

export const maxLengthValidator = (model, field, length, message = '') => () => {
  if (model[field].length > length) {
    throw { field, message };
  }
};

export const minLengthValidator = (model, field, length, message = '') => () => {
  if (model[field].length < length) {
    throw { field, message };
  }
};

export const minAmountValidator = (model, field, amount, message = '') => () => {
  if (model[field] < amount) {
    throw { field, message };
  }
};

export const maxAmountValidator = (model, field, amount, message = '') => () => {
  if (model[field] > amount) {
    throw { field, message };
  }
};

export const fieldsMatchValidator = (model, field, secondField, message = '') => () => {
  if (model[field] !== model[secondField]) {
    throw { field: secondField, message };
  }
};

import { computed, ref } from '@vue/composition-api';
import { validate } from '@/utils/validation';

export const useValidation = (validationRules) => {
  let rules;
  if (typeof validationRules === 'function') {
    rules = computed(validationRules);
  } else {
    rules = ref(validationRules);
  }

  const errorList = ref([]);
  const errorsMap = computed(() => errorList.value.reduce((acc, cur) => ({
    ...acc,
    ...(!acc[cur[0]] ? {
      [cur[0]]: cur[1],
    } : {}),
  }), {}));

  const validateModel = () => {
    const {
      result: valid,
      errors,
    } = validate(...rules.value);

    errorList.value = valid
      ? []
      : errors;

    return valid;
  };

  return [
    validateModel,
    errorsMap,
  ];
};
